<template>
 <div class="form form-box">
   <form-view v-if="logicShowHandle(item,formData)" :key="index" v-for="(item,index) in child" :formData="formData" :form="item"></form-view>
 </div>
</template>

<script>
import formMixin from '@/mixin/FormMixin'
import formView from "@/components/FormView.vue";
export default {
  mixins:[formMixin],
  components:{formView},
  name: "QuBox",
  props:['child','formData'],
  created() {
  }
}
</script>

<style scoped>
.form-box {
  background: #fff;
  padding:0 20px 20px 20px;
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 16px #ebebeb;
}
</style>

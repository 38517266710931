<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div style="text-align: center" v-if="60 > form.value">低风险人群</div>
    <div style="text-align: center" v-if="form.value > 60 && 80 >= form.value">中风险人群</div>
    <div style="text-align: center" v-if="form.value > 80">高风险人群</div>
  </div>
</template>

<script>
export default {
  name: "QuScore",
  props:['form','formData'],
  watch:{
    formData:{
      handler(newValue, oldValue) {
        let matchingForm = this.form.formConfig.matchingForm
        if (!matchingForm || matchingForm.length == 0){
          return
        }
        let formArr = []
        for (let i = 0; i <matchingForm.length ; i++) {
          this.findForm(this.formData,formArr,matchingForm[i])
        }
        let score = 0
        for (let i = 0; i < formArr.length; i++) {
          score += this.getScore(formArr[i])
        }
        this.form.value = score
      },
      deep: true
    }
  },
  data(){
    return{
    }
  },
  methods:{
    getScore(formItem){
      let score = 0
      let scoreArr = formItem.score
      //如果匹配题目没有value 则不进行判断 因为就没做
      if (!formItem.value || !this.logicShowHandle(formItem,this.formData)){
        return 0;
      }
      for (let i = 0; i < scoreArr.length; i++) {
        let expression = scoreArr[i].expression
        if (formItem.value instanceof Array){
          expression = expression.replaceAll("value",`${this.getArrayExpression(formItem.value)}`)
        }else {
          expression = expression.replaceAll("value",`'${formItem.value}'`)
        }
        if (formItem.formConfig.formId == '1682085703134wzhxgdpi'){
          console.log(expression)
        }
        let result = eval(expression)
        if (result){
          score += scoreArr[i].score
        }
      }
      return score
    },
    getArrayExpression(arr){
      return JSON.stringify(arr)
    },
    findForm(formData,formArr,formId){
      for (let i = 0; i < formData.length; i++) {
        if (formData[i].tagType == 'form' && formData[i].formConfig.formId == formId){
          formArr.push(formData[i])
        }
        if (formData[i].child && formData[i].child.length > 0){
          this.findForm(formData[i].child,formArr,formId)
        }
      }
    },
    logicShowHandle(item,formData){
      if (!item.logicShow || !item.logicShow.checkValue || item.logicShow.checkValue.length == 0){
        return true
      }
      let formArr = []
      this.findForm(formData,formArr,item.logicShow.formId)
      if (formArr.length == 0){
        return true
      }
      let formItem = formArr[0]
      let index = item.logicShow.checkValue.findIndex(o=>formItem.value == o)
      return index != -1
    }
  }
}
</script>

<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}
.warn{
		color: #f76474;
		font-size: 14px;
		margin-top: 5px;
		text-align: left;
	}
.content-form {
  margin-bottom: 30px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

</style>

<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{form.formConfig.title}}</div>
    <van-field
        v-model="form.value"
        is-link
        readonly
        :placeholder="form.formConfig.placeholder"
        @click="show = true"
    />
    <van-popup v-model:show="show" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          :title="form.formConfig.placeholder"
          :options="options"
          @close="show = false"
          @finish="onFinish"
      />
    </van-popup>
  </div>

</template>

<script>
import { useCascaderAreaData } from '@vant/area-data';
console.log(useCascaderAreaData())
export default {
  name: "QuRegion",
  props: ['form'],
  data() {
    return {
      show:false,
      cascaderValue:'',
      options:[]
    }
  },
  methods:{
    onFinish(value){
      this.form.value = value.selectedOptions.map((option) => option.text).join('/');
      this.show=false
    },
  },
  created() {
    let province = useCascaderAreaData().filter(o=>o.value == '610000')
    province[0].children = province[0].children.filter(o=>o.value == '610500')
    this.options = province
  }
}
</script>


<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}
.warn{
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}
.content-form {
  margin-bottom: 20px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

.content-form {
  margin-bottom: 20px;
}
/deep/ .van-cell{
    border-radius: 5px;
    border: 1px solid #dedede
}
/deep/ .van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #fff;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
</style>


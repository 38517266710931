<template>
	<div>
		<div class="head">
		  <img src="../assets/backgimg.png">
		</div>
		<div class="content">
			<div class="success form-box">
				<div class="success-img">
					<img src="../assets/success.png" alt="">
				</div>
				<div class="success-title" v-if="60 > value">温馨提示！</div>
				<div class="success-title" v-if="value >= 60 && 80 >= value">温馨提示！</div>
				<div class="success-title" v-if="value > 80">温馨提示！</div>

				<div class="success-text-big" v-if="60 > value">根据您本次提交的信息，经系统评判您处于 <span class="colo-red">【低风险】</span>人群，请您继续保持良好的生活习惯，并密切注意乳腺的健康管理。建议您每月月经后1周内进行乳腺自查，每年参与1次常规性乳腺疾病筛查。</div>
				<div class="success-text-big" v-if="value >= 60 && 80 >= value">根据您本次提交的信息，经系统评判您处于 <span class="colo-red">【中风险】</span>人群，具有潜在患病风险，需要积极预防。请您尽快接受乳腺疾病筛查，并每半年进行一次体检。</div>
				<div class="success-text-big" v-if="value > 80">根据您本次提交的信息，经系统评判您处于<span class="colo-red">【高风险】</span>人群，请您尽快接受乳腺疾病筛查，经专家团队风险评估后，给出专业建议并进行规范诊治，以求早期发现恶性病变。</div>

				  <!-- <div class="colo-red" v-if="60 > value">低风险人群</div>
				  <div class="colo-red" v-if="value >= 60 && 80 >= value">中风险人群</div>
				  <div class="colo-red" v-if="value > 80">高风险人群</div> -->

				<div class="line"></div>
				<div class="success-text"  v-if="60 > value">乳腺自查操作</div>
				<div class="success-text"  v-if="value >= 60 && 80 >= value">乳腺筛查安排</div>
				<div class="success-text"  v-if="value > 80">乳腺筛查安排</div>
				<div class="success-text-s">请识别二维码观看专家讲解视频</div>


				<div class="codeimg" v-if="60 > value">
					<img src="../assets/codeimg1.png" alt="">
				</div>
				<div class="codeimg"  v-if="value >= 60 && 80 >= value">
					<img src="../assets/codeimg2.png" alt="">
				</div>
				<div class="codeimg" v-if="value > 80">
					<img src="../assets/codeimg2.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		//import引入的组件需要注入到对象中才能使用
		components: {},
		data() {
			return {
        value:0
      };
		},
		//监听属性 类似于data概念
		computed: {},
		//方法集合
		methods: {
		},
		//生命周期 - 创建完成（可以访问当前this实例）
		created() {
      this.value = this.$route.query.score
    },
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
		document.body.style.backgroundColor = "rgba(237 237 237)";
		},
		beforeCreate() {}, //生命周期 - 创建之前
		beforeMount() {}, //生命周期 - 挂载之前
		beforeUpdate() {}, //生命周期 - 更新之前
		updated() {}, //生命周期 - 更新之后
		beforeDestroy() {}, //生命周期 - 销毁之前
		destroyed() {}, //生命周期 - 销毁完成
		activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
	}
</script>
<style scoped>
	.content{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;

	}
	.head{
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		opacity: .5;
	}
	.head img {
		width: 100%;
		height: 100%;
	}

	.form-box {
		background: #fff;
		padding:40px 20px;
		margin-top: 20px;
		border-radius: 10px;
		box-shadow:0 5px 16px #dddddd;
		}
	.success{
		padding: 40px 20px;
		width: 80%;
		text-align: center;
	}
	.success-img img{
		width: 80px;
		height: 80px;
	}
	.success-title{
		margin-top: 20px;
		font-size: 18px;
		color: #333;
	}
	.success-text{
		margin-top: 10px;
		font-size: 16px;
	}
	.success-text-s{
		font-size: 16px;
		margin-top: 5px;
	}
	.colo-red{
		color: #f34255;
		margin-top: 5px;
	}
	.colo-green{
		color: #41cc8b;
		margin-top: 5px;
	}
	.success-text-big{
		color: #333;
		margin-top: 10px;
		line-height: 25px;
	}
	.line{
		width: 100%;
		height: 1px;
		margin: 20px 0;
		background: #f1f1f1;
	}
	.codeimg{
		margin-top: 10px;
	}
	.codeimg img{
		width: 200px;
		height: 200px;
	}
</style>

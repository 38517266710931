<template>
  <div id="app">
       <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',

   data() {
    return {
    };
  },
  methods: {
  }
}
</script>
<style>
#app {
  font-family: '微软雅黑' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #0d1b47 !important;
}
body{
	padding: 0;
	margin: 0;
}
</style>

<template>
  <div class="form-list">
    <div class="form-title">
    {{formConfig.title}}
    </div>
  </div>
</template>

<script>
export default {
  name: "QuTitle",
  props:['formConfig']
}
</script>

<style scoped>
.form-list {
  width: 70%;
  height: 40px;
  margin: auto;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
  border: none;
  font-size: 16px;
  background-image: linear-gradient(-90deg, #f76474, #f78794);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0 5px 16px #ffdadf;
  text-align: center;
  margin-bottom: 20px;
}
.form-title {
  font-weight: 600;
  margin-bottom: 10px;
}
</style>

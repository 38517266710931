<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{form.formConfig.title}}</div>
    <div class="van-field-box">
		<van-field
		    v-model="form.value"
		    label="手机号"
		    placeholder="请输入手机号"
		/>
		<van-field
		    v-model="form.code"
		    center
		    clearable
		    label="短信验证码"
		    placeholder="请输入短信验证码"
		>
		<template #button>
		    <van-button size="small" type="primary" @click="sendCode">{{ buttonText == -1 ? '发送验证码':buttonText + '秒'}}</van-button>
		  </template>
		</van-field>
	</div>
  </div>

</template>

<script>
import { showSuccessToast, showFailToast } from 'vant';

export default {
  name: "QuPhoneCode",
  props: ['form'],
  data() {
    return {
      buttonText:-1
    }
  },
  methods:{
    sendCode(){
      if (this.buttonText != -1){
        return
      }
      this.$http.post("sms/sendCode",{
        phone:this.form.value
      }).then(res=>{
        this.buttonText = 61
        this.setButtonText()
      })
    },
    setButtonText(){
      if (this.buttonText <= 0){
        this.buttonText = -1
      }else{
        this.buttonText --
        setTimeout(this.setButtonText,1000)
      }
    }
  }
}
</script>


<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}
.warn{
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}
.content-form {
  margin-bottom: 20px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

.content-form {
  margin-bottom: 20px;
}
/deep/ .van-cell{
	padding: 10px 0;
}
.van-field-box{
	padding: 10px;
	border: 1px solid #dedede;
	border-radius: 5px;
}
</style>


import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import Vue from 'vue'
import isPlainObject from 'lodash/isPlainObject'
import { Toast } from 'vant';
const http = axios.create({
  baseURL: window.SITE_CONFIG.apiURL,
  timeout: 1000 * 180,
  withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  // config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN'
  // config.headers.token = Cookies.get('center-token') || ''
  // 默认参数
  var defaults = {
  }
  // let userId = window.sessionStorage.getItem("user_id")
  // if (userId) {
  //   config.headers['userId'] = userId
  // }
  // 防止缓存，GET请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    }
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      config.data = qs.stringify(config.data)
    }
  }

  // get序列化
  config.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }

  return config
}, error => {
  return Promise.reject(error)
})
/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 407 ) {
    router.replace("/")
    return Promise.reject("登录过期,请重新登录")
  }
  if (response.data.code !== 0) {
      Toast.fail(response.data.msg || response.data.message);
    return Promise.reject(response.data.msg  || response.data.message)
  }
  return response
}, error => {
  console.error(error)
  return Promise.reject(error)
})

export default http

import { Toast } from 'vant';
const mixin = {
    data() {
        return{
            submitLoading:false,
        }
    },
    created() {
    },
    methods:{
        initFormValue(formData,formArr){
            for (let i = 0; i < formData.length; i++) {
                if (formData[i].tagType == 'form'){
                    let form = formArr.find(o=>o.formId == formData[i].formConfig.formId)
                    if (form){
                        formData[i].value = form.value
                    }
                }
                if (formData[i].child && formData[i].child.length > 0){
                    this.initFormValue(formData[i].child,formArr)
                }
            }
        },
        logicShowHandle(item,formData){
            if (!item.logicShow || !item.logicShow.checkValue || item.logicShow.checkValue.length == 0){
                return true
            }
            let formArr = []
            this.findForm(formData,formArr,item.logicShow.formId)
            if (formArr.length == 0){
                return true
            }
            let formItem = formArr[0]
            let index = item.logicShow.checkValue.findIndex(o=>formItem.value == o)
            return index != -1
        },
        findForm(formData,formArr,formId){
            for (let i = 0; i < formData.length; i++) {
                if (formData[i].tagType == 'form' && formData[i].formConfig.formId == formId){
                    formArr.push(formData[i])
                }
                if (formData[i].child && formData[i].child.length > 0){
                    this.findForm(formData[i].child,formArr,formId)
                }
            }
        },
        checkFormHandle(formData){
            let errorArr = []
            this.checkForm(formData,errorArr)
            if (errorArr.length > 0){
                // this.reload()
                let tmpArr = JSON.parse(JSON.stringify(this.formData))
                this.formData.length = 0
                this.formData = tmpArr
                this.$forceUpdate();
                    document
                        .querySelector(`[id='${errorArr[0].formConfig.formId}']`)
                        .scrollIntoView(true);
            }
            return errorArr.length == 0
        },
        checkForm(formData,errorArr){
            if (!formData || formData.length == 0){
                return true
            }
            for (let i = 0; i < formData.length; i++) {
                 if (formData[i].tagType == 'form'){
                     let dom = document
                         .querySelector(`[id='${formData[i].formConfig.formId}']`)
                     if (formData[i].formConfig.required && !formData[i].value && dom){
                         formData[i].formConfig.errorText = '请填写必填项'
                         errorArr.push(formData[i])
                     }else {
                         formData[i].formConfig.errorText = ''
                     }
                 }
                 if (formData[i].child && formData[i].child.length > 0){
                     this.checkForm(formData[i].child,errorArr)
                 }
            }
        },
        getFormDataHandle(formData){
            let formArr = []
             this.getFormData(formData,formArr)
            return formArr
        },
        getFormData(formData,formArr){
            if (!formData || formData.length == 0){
                return true
            }
            if (!formData || formData.length == 0){
                return true
            }
            for (let i = 0; i < formData.length; i++) {
                if (formData[i].tagType == 'form'){
                     formArr.push({
                         formId:formData[i].formConfig.formId,
                         label:formData[i].label,
                         value:formData[i].value
                     })
                }
                if (formData[i].child && formData[i].child.length > 0){
                    this.getFormData(formData[i].child,formArr)
                }
            }
        }
    }
}
export default mixin;

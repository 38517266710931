<script>
function makeDataObject() {
  return {
    attrs: {},
    props: {},
    nativeOn: {},
    on: {},
    style: {}
  }
}


export default {
  name: "FormView",
  props:['form','formData'],
  render(createElement, context) {
    const dataObject = makeDataObject()
    dataObject.props.formConfig = this.form.formConfig
    dataObject.props.child = this.form.child
    dataObject.props.form = this.form
    dataObject.props.formData = this.formData
    if (this.form.tag == 'qu-score'){
      dataObject.style['display']='none'
    }
    return createElement(this.form.tag, dataObject, this.form.children)
  }
}
</script>

<style scoped>

</style>

<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{ form.formConfig.title }}</div>
    <input v-model="fileInputName" :placeholder="form.formConfig.placeholder" readonly type="text"
           >
    <div v-if="form.formConfig.errorText" class="warn">*{{ form.formConfig.errorText }}</div>
    <div v-if="form.formConfig.prompt" class="supplement">{{ form.formConfig.prompt }}</div>
    <van-uploader :before-delete="deleteFileHandle" :max-count="6" v-model="fileList" :after-read="afterFile" :before-read="beforeRead"/>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: "QuUploadImage",
  props: ['form'],
  data(){
    return{
      fileList:this.formatUploadFileUrl(this.form.value)
    }
  },
  computed:{
    fileInputName(){
      let arr = this.form.value.split(",").filter(o=>o)
      return  arr.length == 0?'':'当前已选择'+(arr.length) + '张照片'
    }
  },
  methods:{
    deleteFileHandle(e){
      let arr = this.form.value.split(",")
      arr.splice(arr.findIndex(o=> o == e.url),1)
      this.form.value = arr.join(",")

      this.fileList.splice(this.fileList.findIndex(o=> o == e.url),1)
    },
    formatFileUrl(val){
      return  val.split(",").filter(o=>o)
    },
    formatUploadFileUrl(val){
      let arr = val.split(",").filter(o=>o).map(o=>{
        return {
          url:o
        }
      })
      return  arr
    },
    afterFile(file){
      console.log(this.fileList)
      const formData = new FormData();
      formData.append("file",file.file);
      file.status = "uploading"
      this.$http.post('oss/upload',formData,{
        'Content-type' : 'multipart/form-data'
      }).then(res=>{
        file.status = "done"
        let arr = this.formatFileUrl(this.form.value)
        arr.push(res.data.data)
        file.url = res.data.data
        this.form.value = arr.join(",")
        // 上传成功后的处理
      },err=>{
        file.status = "failed"
        // 出现错误时的处理
      })
    },
    beforeRead(file) {
      if (file.type.indexOf('image') == -1) {
        Toast('请上传图片');
        return false;
      }
      return true;
    },
  }
}
</script>

<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

.warn {
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.content-form {
  margin-bottom: 30px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

/deep/ .van-uploader{
	margin-top: 10px;
}
/deep/ .van-uploader__preview-image {
    display: block;
    width: 90px;
    height: 90px;
    overflow: hidden;
}
/deep/ .van-uploader__upload {
    width: 90px;
    height: 90px;
}
</style>

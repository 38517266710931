<template>
  <div class='home'>
    <div class="head">
      <img alt="" src="https://questionnaire.tumorcompass.com/img.png">
    </div>
    <div ref="pronbit" class="content">
      <div class="text">
        <div class="text-title">渭南市女性疾病健康教育与巡诊活动</div>
        <div class="text-content">2020年，乳腺癌已经成为全球发病率最高的恶性肿瘤，在国内，乳腺癌发病率也位居妇女恶性肿瘤首位，严重危害妇女的身心健康。目前，通过综合治疗手段，
          乳腺癌已成为疗效最佳的实体肿瘤之一。为进一步加强全市乳腺疾病健康科普教育，提升妇女群众对乳腺癌“早预防、早发现、早诊断、早治疗”的认知，提高医疗机构乳腺疾病诊疗水平，
          规范乳腺疾病诊疗行为，保障医疗质量和医疗安全，由渭南市卫生健康委员会、渭南市妇女联合会支持，陕妇儿基金会“爱康母婴基金”公益援助，《全国母婴医疗健康科普库》平台支持，
          渭南市妇幼保健院实施，联合发起渭南市乳腺疾病健康教育与巡诊活动。
        </div>
      </div>
      <form-view v-if="logicShowHandle(item,formData)" v-for="(item,index) in formData" :key="index" :formData="formData" :form="item"></form-view>
      <div class="bottom-text">
        <div>成都指南者互联网医院提供技术支持</div>
		<div style="margin-top: 5px">医生说·育儿-母婴医疗健康科普库</div>
        <div style="margin: 5px 0;">本系统已通过公安机关三级等保认证备案</div>
        <div class="bottom-text-img">
          <div>
            <img alt="" src="../assets/authentication.png">
          </div>
          <div class="baozhang">保障数据安全</div>
        </div>
      </div>
      <transition name="van-fade">
      <div class="text-button" @click="fill">
        <van-button   type="warning">提交</van-button>
      </div>
      </transition>
    <!--  <div class="pop">
        <div class="pop-content">
          <div class="pop-img">
			<img src="../assets/pop-img.png" alt="">
          </div>
          <div class="pop-titile">当前处于高风险等级，确认要提交吗？</div>
		  <div class="pop-button">
			  <button>确认提交</button>
		  </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import formView from '@/components/FormView.vue'
import formData from './data.json'
import formMixin from '@/mixin/FormMixin'
import { Toast } from 'vant';
// ,
// {
//   "tagType": "form",
//     "tag": "qu-region",
//     "formConfig": {
//   "formId": "16824729fjs28gdtkcamz",
//       "title": " 8.所属乡镇",
//       "required": true,
//       "placeholder": "请选择所属地区"
// },
//   "label":"region",
//     "value": ""
// }

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {formView},
  mixins:[formMixin],
  data() {
    return {
      formData: formData,
      selects: 100,
      fillShow: false,
      userId:'',
      phone:'',
      id:''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //方法集合
  methods: {
    fill() {
      let v = this.checkFormHandle(this.formData)
      if (!v){
        return
      }
      let formArr = this.getFormDataHandle(this.formData)
      Toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let codeArr = []
      this.findForm(this.formData,codeArr,"1682082262628wgsftzim")
      if (!codeArr[0].code){
        Toast.fail("请输入验证码")
      }
      this.$http.post("sms/verifyCode",{
        phone:codeArr[0].value,
        code:codeArr[0].code
      }).then(()=>{
        let testArr = []
        this.findForm(this.formData,testArr,"1623145703134onrzitsl")
        this.$http.post("questionnaire/save",{
          userId:this.userId,
          phone:this.phone,
          id:this.id,
          formData:formArr
        }).then(res=>{
          Toast.clear();
          this.$router.replace('/fill?score='+testArr[0].value)
        })
      }).catch(()=>{
        setTimeout(()=>{
          Toast.clear();
        },1500)
      })
    },
    algorithm() {
      let abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'g', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      let [max, min] = [Math.floor(Math.random() * (10 - 7 + 1) + 1), Math.floor(Math.random() * (17 - 10 + 1) + 17)];
      abc = abc.sort(() => 0.4 - Math.random()).slice(max, min).slice(0, 8).join("");
      var a = new Date().getTime() + abc;
      return a
    },
    handleScrollx(e) {
      // 返回滚动条垂直滚动距离
      let scrollTop =document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =document.documentElement.scrollHeight || document.body.scrollHeight;
      let numHeight = scrollTop + clientHeight;
      if (scrollHeight > clientHeight && numHeight > scrollHeight - 3500) {
        this.fillShow = true
      }else {
        this.fillShow = false
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let arr = []
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].child){
        for (let j = 0; j < formData[i].child.length; j++) {
          if (formData[i].child[j].score){
            arr.push(formData[i].child[j].formConfig.formId)
          }
        }
      }
    }
    this.userId = this.$route.query.userId
    this.phone = this.$route.query.phone
    this.id = this.$route.query.id
    window.addEventListener('scroll', this.handleScrollx)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    document.body.style.backgroundColor = "rgb(243 234 236)";
    window.addEventListener('scroll', this.handleScrollx)
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollx);
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
	.content {
		padding: 20px 20px 100px 20px;
		margin-top: -115%;
		position: absolute;
	}

	.head img {
		width: 100%;
	}

	.text {
		background: #fff;
		padding: 20px;
		border-radius: 15px;
		box-shadow: 0 5px 16px #e9d4cf;
	}

	.text-title {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
		text-align: center;
	}

	.text-content {
		font-size: 15px;
		line-height: 25px;
		text-indent: 2em;
		letter-spacing: 1px;
		color: #999;
	}

	.text-button {
		position: fixed;
		width: 100%;
		height: 60px;
		background: #fff;
		bottom: 0;
		left: 0;
		padding: 10px;
	}

	.text-button button {
		height: 55px;
		width: 95%;
		background: #f88692;
		border: 1px solid #f88692;
		border-radius: 5px;
		box-shadow: 0 5px 16px #e7c5d1;
		color: #fff;
		font-size: 16px;
		font-weight: 600;
	}

	.bottom-text {
		justify-content: flex-start;
		align-items: center;
		padding: 20px;
		font-size: 14px;
		margin-top: 10px;
		border-radius: 15px;
		box-shadow: 0 5px 16px #dedede;
		background: #fff;
		text-align: center;
		color: #0D1B47;
	}

	.bottom-text img {
		width: 12px;
		height: 14px;
		margin-right: 5px;
	}

	.form-list {
		width: 80%;
		margin: auto;
		height: 45px;
		line-height: 45px;
		font-weight: 600;
		color: #fff;
		border: none;
		font-size: 16px;
		background-image: linear-gradient(-90deg, #f76474, #f78794);
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		box-shadow: 0 5px 16px #ffdadf;
		text-align: center;
		margin-bottom: 20px;
	}

	.form-box {
		background: #fff;
		padding: 0 20px 20px 20px;
		margin-top: 20px;
		border-radius: 15px;
		box-shadow: 0 5px 16px #ebebeb;
	}
	.form-title {
		font-weight: 600;
		margin-bottom: 10px;
	}

	.list {
		font-weight: 600;
		margin-bottom: 15px;
		font-size: 16px;
		color: #0D1B47;
	}

	.list span {
		color: #f88692;
		margin-right: 2px;
	}

	.content-form input {
		width: 90%;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		font-size: 16px;
		border-radius: 5px;
		border: 1px solid #dedede
	}

	.content-form {
		margin-bottom: 30px;
	}

	.form .content-form:nth-last-child(1) {
		margin-bottom: 10px;
	}

	.cancel-list {
		width: 100%;
		height: auto;
		padding-bottom: 0.2rem;
	}

	.CancelOrderCause-layout {
		font-family: PingFang SC;
		background: #f6f6f6;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	.cancel-title {
		background: #ffffff;
		margin-top: 0.2rem;
		width: 100%;
		height: 1.18rem;
		border-radius: 0.2rem 0.2rem 0 0;
		font-size: 0.36rem;
		font-stretch: normal;
		line-height: 0.98rem;
		box-sizing: border-box;
		padding-top: 0.2rem;
		padding-left: 0.32rem;
		letter-spacing: 0rem;
		color: #191f25;
	}

	.multiple-choice-line {
		position: absolute;
		height: 0.02rem;
		top: 0;
		left: 0.88rem;
		right: 0;
		background-color: #ededee;
	}

	.multiple-choice-img {
		width: 20px;
		height:20px;
		margin-right:20px;
	}

	.multiple-choice-list {
		width: 100%;
		height: 0.88rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 0 10px;
	}
	.cancel-multiple-choice{
		background: #f7f7f7;
		padding:15px 10px;
		margin-bottom: 10px;
		border-radius: 50px;
	}
	.select-content{
		margin-bottom:30px
	}
	.supplement{
		color:#219951;
		line-height: 22px;
		font-size: 13px;
		margin-top: 10px;
	}
	.bottom-text-img{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.warn{
		color: #f76474;
		font-size: 14px;
		margin-top: 5px;
	}
	.baozhang{
		margin-top: -5px;
	}
	.pop{
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top:0;
		z-index: 99;
		background: rgba(0, 0, 0, .5);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.pop-content{
		background: #fff;
		border-radius: 10px;
		padding:40px 20px;
		width: 60%;
	}
	.pop-titile{
		text-align: center;
		line-height: 24px;
		margin: 30px 0;
		font-size: 13px;
	}
	.pop-button button{
		height: 50px;
		width: 100%;
		background: #f88692;
		border: 1px solid #f88692;
		border-radius: 5px;
		box-shadow: 0 5px 16px #e7c5d1;
		color: #fff;
		font-size: 16px;
		font-weight: 600;
	}
	.pop-img img{
		width: 80px;
		height: 80px;
		margin: auto;
		display: table;
	}
	/deep/ .content-form  textarea{
		border-radius: 5px;
		border: 1px solid #dedede;
		width: 90%;
		padding: 10px;
		font-size: 14px;
	}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import field from '../views/field.vue'
import echo from "@/views/echo.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/fill',
    name: 'field',
    component: field
  },
  {
    path: '/echo',
    name: 'echo',
    component: echo
  }
]

const router = new VueRouter({
  routes
})

export default router

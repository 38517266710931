import Vue from 'vue'

import QuBox from '@/components/QuBox.vue'
import QuTitle from '@/components/QuTitle.vue'
import QuInput from "@/components/QuInput.vue";
import QuRadio from "@/components/QuRadio.vue";
import QuDatePicker from "@/components/QuDatePicker.vue";
import QuUploadImage from "@/components/QuUploadImage.vue";
import QuScore from "@/components/QuScore.vue";
import QuCheckbox from "@/components/QuCheckbox.vue";
import QuRegion from "@/components/QuRegion.vue";
import QuPhoneCode from "@/components/QuPhoneCode.vue";
import QuPicker from "@/components/QuPicker.vue";
Vue.component(QuPicker.name,QuPicker)
Vue.component(QuRegion.name,QuRegion)
Vue.component(QuPhoneCode.name,QuPhoneCode)
Vue.component(QuCheckbox.name,QuCheckbox)
Vue.component(QuBox.name,QuBox)
Vue.component(QuScore.name,QuScore)
Vue.component(QuTitle.name,QuTitle)
Vue.component(QuInput.name,QuInput)
Vue.component(QuRadio.name,QuRadio)
Vue.component(QuDatePicker.name,QuDatePicker)
Vue.component(QuUploadImage.name,QuUploadImage)


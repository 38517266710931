import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import '@/utils/loadCompon'
import http from './utils/request'
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from "moment"
Vue.prototype.$moment = moment;
Vue.use(Vant);
Vue.prototype.$http = http
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{ form.formConfig.title }}</div>
    <input v-model="form.value" :placeholder="form.formConfig.placeholder" readonly type="text"
           @click="showPicker = true">
    <div v-if="form.formConfig.errorText" class="warn">*{{ form.formConfig.errorText }}</div>
    <div v-if="form.formConfig.prompt" class="supplement">{{ form.formConfig.prompt }}</div>

    <van-popup v-model="showPicker" position="bottom" round>
      <van-picker  show-toolbar  @cancel="onCancel"  @confirm="onConfirm" :columns="form.formConfig.options" />

    </van-popup>
  </div>
</template>

<script>
export default {
  name: "QuPicker",
  props: ['form'],
  data() {
    return {
      currentDate:new Date(),
      maxDate: new Date(),
      showPicker: false
    }
  },
  created() {

  },
  methods: {
    onCancel(){
      this.showPicker = false
    },
    onConfirm(val) {
      this.form.value = val
      this.showPicker = false
    },
  }
}
</script>

<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

.warn {
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.content-form {
  margin-bottom: 30px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

</style>

var render, staticRenderFns
import script from "./FormView.vue?vue&type=script&lang=js&"
export * from "./FormView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f670b50",
  null
  
)

export default component.exports
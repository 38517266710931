<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{form.formConfig.title}}</div>
    <textarea v-if="form.formConfig.type && form.formConfig.type == 'textarea'" v-model="form.value" :placeholder="form.formConfig.placeholder"></textarea>
    <input v-else v-model="form.value" :placeholder="form.formConfig.placeholder" text="text">
    <div class="warn" v-if="form.formConfig.errorText">*{{ form.formConfig.errorText }}</div>
    <div class="supplement" v-if="form.formConfig.prompt">{{ form.formConfig.prompt }}</div>
  </div>
</template>

<script>
export default {
  name: "QuInput",
  props:['form'],
  data(){
    return{
    }
  }
}
</script>

<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}
.warn{
		color: #f76474;
		font-size: 14px;
		margin-top: 5px;
		text-align: left;
	}
.content-form {
  margin-bottom: 30px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

</style>

<template>
  <div :id="form.formConfig.formId" class="select-content">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{form.formConfig.title}}</div>
    <div class="content-form-select">
      <div class="cancel-multiple-choice"   v-for="(item, index) in form.formConfig.options" :key="index">
        <div class="multiple-choice-list" @click="changeHanle(item, index)">
          <div class="multiple-choice-text">{{ item }}</div>
          <img :src="form.value.indexOf(item) != -1 ? activeIcon : inactiveIcon" alt=""
               class="multiple-choice-img" />
        </div>
      </div>
      <div class="warn" v-if="form.formConfig.errorText">*{{ form.formConfig.errorText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuCheckbox",
  props:['form'],
  data(){
    return{
      activeIcon: '//image.greenplayer.cn/share/img/baoxian/icon_checked.svg',
      inactiveIcon: '//image.greenplayer.cn/share/img/baoxian/icon_unchecked.svg',
    }
  },
  methods:{
    changeHanle(item) {
      if (!this.form.value){
        this.form.value = []
      }
      let index = this.form.value.findIndex(o=>o == item)
      if (index == -1){
        this.form.value.push(item)
      }else{
        this.form.value.splice(index,1)
      }
    },
  }
}
</script>

<style scoped>
.select-content{
  margin-bottom:30px
}
.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
  text-align: left;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.multiple-choice-img {
  width: 20px;
  height:20px;
  margin-right:20px;
}

.multiple-choice-list {
  width: 100%;
  height: 0.88rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 10px;
}
.cancel-multiple-choice{
  background: #f7f7f7;
  padding:15px 10px;
  margin-bottom: 10px;
  border-radius: 50px;
}
.select-content{
  margin-bottom:30px
}
.supplement{
  color:#219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.multiple-choice-list {
  width: 100%;
  height: 0.88rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 10px;
}
.cancel-multiple-choice{
  background: #f7f7f7;
  padding:15px 10px;
  color: #999;
  margin-bottom: 10px;
  border-radius: 50px;
}
.select-content{
  margin-bottom:30px
}
.supplement{
  color:#219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}
.bottom-text-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.warn{
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
}
.baozhang{
  margin-top: -5px;
}
</style>

<template>
  <div :id="form.formConfig.formId" class="content-form">
    <div class="list"><span v-if="form.formConfig.required">*</span>{{ form.formConfig.title }}</div>
    <input v-model="form.value" :placeholder="form.formConfig.placeholder" readonly type="text"
           @click="showPicker = true">
    <div v-if="form.formConfig.errorText" class="warn">*{{ form.formConfig.errorText }}</div>
    <div v-if="form.formConfig.prompt" class="supplement">{{ form.formConfig.prompt }}</div>

    <van-popup v-model="showPicker" position="bottom" round>
      <van-datetime-picker
          v-model="currentDate"
          :formatter="formatter"
          :max-date="maxDate"
          title="选择年月日"
          type="date"
          @cancel="showPicker = false"
          @confirm="changeDateHandle"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "QuDatePicker",
  props: ['form'],
  data() {
    return {
      currentDate:new Date(),
      maxDate: new Date(),
      showPicker: false
    }
  },
  created() {
    if (this.form.formConfig.init){
      if (this.form.formConfig.init == 'now'){
        this.form.value = this.$moment().format("YYYY-MM-DD")
      }
    }
    console.log(this.form)
  },
  methods: {
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    formatDate(value = Date.now(), format = "Y-M-D h:m:s") {
      const formatNumber = n => `0${n}`.slice(-2);
      const date = new Date(value);
      const formatList = ["Y", "M", "D", "h", "m", "s"];
      const resultList = [];
      resultList.push(date.getFullYear().toString());
      resultList.push(formatNumber(date.getMonth() + 1));
      resultList.push(formatNumber(date.getDate()));
      resultList.push(formatNumber(date.getHours()));
      resultList.push(formatNumber(date.getMinutes()));
      resultList.push(formatNumber(date.getSeconds()));
      for (let i = 0; i < resultList.length; i++) {
        format = format.replace(formatList[i], resultList[i]);
      }
      return format;
    },
    changeDateHandle(val) {
      this.form.value = this.formatDate(val,'Y-M-D')
      this.showPicker = false
    },
  }
}
</script>

<style scoped>

.supplement {
  color: #219951;
  line-height: 22px;
  font-size: 13px;
  margin-top: 10px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

.warn {
  color: #f76474;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.content-form {
  margin-bottom: 30px;
}

.form .content-form:nth-last-child(1) {
  margin-bottom: 10px;
}

.list {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}

.list span {
  color: #f88692;
  margin-right: 2px;
}

.content-form input {
  width: 90%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dedede
}

</style>
